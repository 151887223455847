/* Original CSS */

.typing-animation {
  overflow: hidden; /* Hide overflowing content */
  white-space: pre-wrap; /* Allow text to wrap */
  animation: typing 5s steps(40, end), cursor-blink 0.5s infinite; /* Adjust timing */
  border-right: 0.1em solid; /* Simulate cursor */
  margin: 0; /* Remove margin to prevent shifting */
  padding: 0; /* Remove padding to prevent shifting */
}

@keyframes typing {
  from { width: 0; }
}

@keyframes cursor-blink {
  from, to { border-color: transparent; }
  50% { border-color: black; } /* Adjust cursor color */
}

.main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%; /* Ensure full width */
}

.inputs {
  display: flex;
  flex-direction: column;
  width: 100%; /* Ensure full width */
  max-width: 800px; /* Limit maximum width */
  margin: auto; /* Center horizontally */
  padding: 20px; /* Add padding */
}

.inputs label {
  align-self: flex-start; /* Align labels to the left side */
  margin-bottom: 5px; /* Add some space between labels and inputs */
}

.inputs textarea,
.inputs input {
  width: calc(100% - 16px); /* Subtract padding and border from width */
  padding: 8px;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 4px;
  resize: vertical;
}

button {
  margin-top: 10px; /* Add some space above the button */
}

/* New CSS for modern look */

.main {
  font-family: Arial, sans-serif; /* Change font for modern look */
}

h1 {
  color: #333; /* Change heading color */
}


button {
  padding: 10px 20px; /* Add padding to button */
  background-color: #007bff; /* Change button color */
  color: #fff; /* Change text color */
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease; /* Add transition effect */
}

button:hover {
  background-color: #0056b3; /* Change button color on hover */
}

label{
  font-size: small;
  margin-top: 10px;
  margin-bottom: 0px;
}

.icons{
  width: 3.9%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}


header{
  width: 100%;
  max-height: 5%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  border-bottom: 1px solid black;
}

.git{
  transition: all 0.1s ease 0.1s;
  size: 2em;
}


.git:hover{
  border-radius: 50%;
  cursor: pointer;
  background-color: #333;
  color: white;
}

.email{
  transition: all 0.1s ease 0.1s;
}


.email:hover{
  border: none;
  cursor: pointer;
  background-color: #333;
  color: white;
}